const logo_img = 'https://podpis.saleziani.sk/images/logo.png';
const facebook_img = 'https://podpis.saleziani.sk/images/facebook_icon.png';
const instagram_img = 'https://podpis.saleziani.sk/images/instagram_icon.png';
const youtube_img = 'https://podpis.saleziani.sk/images/youtube_icon.png';
const web_img = 'https://podpis.saleziani.sk/images/web_icon.png';

function Missing(props) {
    return <span style={{
        color: 'red'
    }}>
        {props.children}
    </span>;
}

export default function EmailSignature({
                                           nameAndSurname,
                                           position,
                                           positionEnglish,
                                           address,
                                           mobile
                                       }) {
    return (
        <div
            style={{
                textAlign: 'left',
                fontFamily: 'Tahoma, sans-serif'
            }}
        >
            <table border="0" cellPadding="0" cellSpacing="0">
                <tbody>
                <tr>
                    <td>
                        <a href="https://saleziani.sk/" target="_blank" rel="noreferrer">
                            <img
                                width="76" height="117"
                                src={logo_img}
                                style={{
                                    display: 'block',
                                    border: 0,
                                    width: '76px',
                                    height: '117px',
                                    margin: '10px 35px 0 8px'
                                }}
                                alt={'Saleziani.sk'}
                            />
                        </a>
                    </td>
                    <td style={{verticalAlign: 'top'}}>
                        <div style={{
                            fontSize: '12px',
                            lineHeight: '15px'
                        }}>
                            <div style={{
                                fontSize: '14px',
                                fontWeight: 'bold',
                                color: '#404040',
                                marginBottom: '5px'
                            }}>{'' === nameAndSurname ?
                                <Missing>Meno
                                    Priezvisko,
                                    SDB</Missing> : nameAndSurname}</div>
                            {position && (<><i style={{color: '#999999'}}>{position}</i><br/></>)}
                            {positionEnglish && (<><i style={{color: '#999999'}}>{positionEnglish}</i><br/></>)}
                            <br/>
                            {mobile && (<span style={{color: '#999999'}}>{mobile}<br/></span>)}
                            {address && (<span style={{color: '#999999'}}>{address}<br/></span>)}
                        </div>
                        <div style={{marginTop: '20px'}}>
                            <a href="https://saleziani.sk/"><img width="21" height="21" src={web_img}
                                style={{
                                    border: 0,
                                    width: '21px',
                                    height: '21px',
                                    marginRight: '18px'
                                }}
                                alt={'web'}
                            /></a>
                            <a href="https://www.facebook.com/saleziani.sk/?locale=sk_SK"><img width="21" height="21" src={facebook_img}
                                style={{
                                    border: 0,
                                    width: '21px',
                                    height: '21px',
                                    marginRight: '18px'
                                }}
                                alt={'facebook'}
                            /></a>
                            <a href="https://www.instagram.com/saleziani.sk/"><img width="21" height="21" src={instagram_img}
                                style={{
                                    border: 0,
                                    width: '21px',
                                    height: '21px',
                                    marginRight: '18px'
                                }}
                                alt={'instagram'}
                            /></a>
                            <a href="https://www.youtube.com/channel/UCXmgCXn80aLsLO6zu2s7N2g"><img width="21" height="21" src={youtube_img}
                                style={{
                                    border: 0,
                                    width: '21px',
                                    height: '21px',
                                    margin: 0
                                }}
                                alt={'youtube'}/></a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <span
                style={{
                    display: 'block',
                    fontSize: '10px',
                    lineHeight: '14px',
                    color: '#999999',
                    marginTop: '20px',
                    maxWidth: '400px'
                }}
            >
                <strong>GDPR:</strong> Saleziáni don Bosca – Slovenská provincia, vlastník tejto emailovej domény, spracúva osobné údaje
            v súlade s právnou úpravou „Zabezpečenie ochrany osobných údajov Rímskokatolíckou cirkvou
            v Slovenskej republike”. Úpravu je <a style={{
                color: '#999999'
            }} href="https://gdpr.kbs.sk/obsah/sekcia/h/cirkev/p/zavazne-predpisy-rkc">možné nájsť tu</a>.
      </span>
        </div>
    );
}
